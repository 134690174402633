import Dashboard from "./Dashboard.vue"
import DashboardOrdering from "./DashboardOrdering.vue"
import DashboardAccounting from "./DashboardAccounting.vue"
import StatisticAccounting from "./StatisticAccounting.vue"

export default [
    {
        path: "/",
        component: Dashboard,
        name: "Dashboard",
        meta: {
            guest: false,
            authenticated: true
        }
    },
    {
        path: "/dashboard_ordering",
        component: DashboardOrdering,
        name: "DashboardOrdering"
    },
    {
        path: "/dashboard_accounting",
        component: DashboardAccounting,
        name: "DashboardAccounting"
    },
    {
        path: "/statistic_accounting",
        component: StatisticAccounting,
        name: "StatisticAccounting"
    }
]