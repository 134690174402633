<template>
  <section class="section">
    <div class="section-header">
      <h1>Accounting</h1>
    </div>
    <div class="section-body" ref="formProducts">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Account Code</label>
            <model-list-select
              v-model="filter.account_code_id"
              :list="account_codes"
              option-value="id"
              option-text="name"
              placeholder="Select Account Code"
            >
            </model-list-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Search</label>
            <input
              type="text"
              class="form-control"
              placeholder="COA / Name / Description / Date / In / Out"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for>Start from</label>
            <input
              type="date"
              class="form-control"
              v-model="filter.start_date"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for>Until</label>
            <input type="date" class="form-control" v-model="filter.end_date" />
          </div>
        </div>
        <div class="col-md-4">
          <button class="btn btn-primary w-100" @click="getData">
            Search
          </button>
        </div>
        <div class="col-md-4">
          <button class="btn btn-primary w-100">Print</button>
        </div>
        <div class="col-md-4">
          <button class="btn btn-primary w-100">Search and Print</button>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header">
          <h4>{{ name }} Table</h4>
        </div>
        <div class="card-body">
          <v-progress-circular
            v-if="loading"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-data-table
            :headers="headers"
            v-if="!loading"
            :items="accountings.data"
            item-key="id"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.reported_at }}</td>
                <td>{{ row.item.account_code_parent || "Not Assign Yet" }}</td>
                <td>
                  {{ row.item.account_code || "Not Assign Yet" }} -
                  {{ row.item.account_code_child || "Not Assign Yet" }}
                </td>
                <td>{{ row.item.status }}</td>
                <td>{{ row.item.payment_type_name }}</td>
                <td>{{ row.item.description }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div class="card-footer text-right">
          <div class="d-inline-block overflow-auto">
            <v-pagination
              v-model="page"
              :length="accountings.last_page"
              :total-visible="accountings.per_page"
              @input="getData"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";
export default {
  name: "Accounting",
  data() {
    return {
      name: "Accounting",
      page: 1,
      pageVisible: 8,
      filter: {
        start_date: "",
        end_date: "",
        account_code_id: "",
        departement_id: "",
        venue_id: "",
        page: 1,
      },
      accountings: [],
      account_codes: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
    this.getAll();
  },
  methods: {
    getAll() {
      apiGet("account_code/not-paginate").then((res) => {
        this.account_codes = res.data.data;
      });
    },
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "dashboard/accounting", {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            start_date: this.filter.start_date,
            end_date: this.filter.end_date,
            account_code_id: this.filter.account_code_id,
            page: this.filter.page,
          },
        })
        .then((result) => {
          this.loading = false;
          this.accountings = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Reported At",
          value: "reported_at",
        },
        {
          text: "Account Group",
          value: "account_code_parent",
        },
        {
          text: "Account Code",
          value: "account_code",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Payment Type",
          value: "payment_type_name",
        },
        {
          text: "Description",
          value: "description",
        },
      ];
    },
  },
};
</script>
